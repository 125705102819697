import type { FC } from 'react'
import React, { memo } from 'react'
import { useIntl } from 'react-intl'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { Divider, Image, InjectHtml } from '@/components/ui'
import type { IActiveSubstance } from '@/services/MarketSearchService/interfaces'
import { ESuggestionType } from '@/services/MarketSearchService/interfaces'
import { getSuggestionLink, makeCategoryParts } from './helpers'
import type { ISuggestionCardProps } from './interfaces'

const SuggestionCard: FC<ISuggestionCardProps> = ({ suggestion }) => {
  const { formatMessage } = useIntl()
  const { name, highlights, withDivider } = suggestion

  let activeSubstance: IActiveSubstance[] | undefined
  let image: string | undefined

  switch (suggestion.type) {
    case ESuggestionType.SKU:
      activeSubstance = suggestion.activeSubstance
      image = suggestion.image
      break
    case ESuggestionType.SUPPLIER:
    case ESuggestionType.PRODUCER:
      image = suggestion.image
      break
    default:
  }

  const { name: highlightedNames } = highlights || {}
  const suggestionLink = getSuggestionLink(suggestion)
  const categoryParts = makeCategoryParts(formatMessage)(suggestion)

  return (
    <>
      <CommonLink url={suggestionLink} spanIfEmpty className="search-suggestion link-no-hover">
        <div className="search-suggestion__body">
          {name && <InjectHtml TagName="p" className="text_body" html={highlightedNames?.[0] || name} />}
          {activeSubstance && (
            <p className="space-holder-top4 text_small">{activeSubstance.map(substance => substance.name).join(' + ')}</p>
          )}
          <p className="search-suggestion-category color_pale-black text_small">
            {categoryParts.map(part => (
              <span className="search-suggestion-category__item">{part}</span>
            ))}
          </p>
        </div>
        <Image src={image} className="search-suggestion__image" hideIfEmpty />
      </CommonLink>
      {withDivider && <Divider className="search-suggestion__divider" />}
    </>
  )
}

export default memo(SuggestionCard)
